
import FormField from '@/components/forms/FormField.vue'
import { InputText } from '@/components/inputs'
import { VerificationTokenReadDto } from 'tradingmate-modules/src/models/api/signup'
import { HttpError, Services } from 'tradingmate-modules/src/services'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    FormField,
    InputText
  }
})

export default class Verification extends Vue {
  @Prop({ required: true })
  private readonly email!: string;

  public httpError: HttpError | null = null

  private hasSentVerificationCode = false
  private isSendingValidation = false
  private isCodeValid: boolean | null = null;
  private verificationToken: VerificationTokenReadDto | null = null;
  private verificationCode = ''
  private isVerifying = false
  private wrongVerification = false

  get verificationError (): string[] | null {
    if (this.wrongVerification) {
      return ['The code does not match']
    } else {
      return null
    }
  }

  sendVerificationCode (): void {
    if (!this.email) return

    console.log(this.email)

    this.isSendingValidation = true
    Services.API.Signup.RequestAccountVerificationCode(this.email)
      .then((verificationTokenModel) => {
        console.log(verificationTokenModel)
        this.verificationToken = verificationTokenModel
        this.hasSentVerificationCode = true
        this.$emit('onCodeReceived')
      })
      .catch((errors) => { this.httpError = errors })
      .finally(() => { this.isSendingValidation = false })
  }

  submitVerificationCode (): void {
    this.isCodeValid = null
    this.wrongVerification = false
    this.isVerifying = true
    if (!this.verificationToken) return
    if (!this.verificationCode) return
    Services.API.Signup.ValidateAccountVerificationCode(this.verificationToken.TokenId, this.email, this.verificationCode)
      .then((resultModel) => {
        if (resultModel.Valid === true) {
          this.isCodeValid = true
          this.$emit('validated')
        } else {
          this.isCodeValid = false
          this.wrongVerification = true
        }
      })
      .catch((errors) => { this.httpError = errors })
      .finally(() => { this.isVerifying = false })
  }
}

