
import AuthPageContent from '@/components/collections/account/AuthPageContent.vue'
import Verification from '@/components/collections/account/Verification.vue'
import Form from '@/components/forms/Form.vue'
import FormField from '@/components/forms/FormField.vue'
import InputText from '@/components/inputs/InputText.vue'
import { Logger, LogLevel } from 'tradingmate-modules/src/debug/Logger'
import { Config } from 'tradingmate-modules/src/environment'
import { Services } from 'tradingmate-modules/src/services'
import { Component, Vue } from 'vue-property-decorator'
import LoginResult from '../../../../tradingmate-modules/src/models/api/users/LoginResult'

@Component({
  components: {
    AuthPageContent,
    Form,
    FormField,
    InputText,
    Verification
  }
})
export default class Login extends Vue {
  private loading = true;
  private disabled = false;

  private email = '';
  private password = '';

  private wrongPassword = false;
  private isLockedOut = false;
  // private needsPasswordReset = false;
  private needsVerification = false;
  private isValidated = false;

  mounted (): void {
    this.loading = false
  }

  get linkToTermsOfServices (): string {
    return Config.WebsiteUrls.Cdn + '/documents/terms-of-use.pdf'
  }

  get linkToPrivacyPolicy (): string {
    return Config.WebsiteUrls.Cdn + '/documents/privacy-policy.pdf'
  }

  async submit (): Promise<void> {
    this.loading = true

    let loginResult: LoginResult
    try {
      loginResult = await Services.API.Users.Login({
        Email: this.email,
        Password: this.password,
        ConsumerId: Config.OAuth.ConsumerId
      })
    } catch (error) {
      Logger.Log(LogLevel.Critical, error)
      this.$router.push('/error/500')
      return
    }

    if (loginResult.PasswordNeedsReset) {
      this.$router.push({
        path: '/account/reset',
        query: { prr: 'npr' }
      })
    }

    if (loginResult.NeedsVerification) {
      this.needsVerification = true
      return
    }

    if (loginResult.Success) {
      await Services.CredentialManager.SetOAuthCredential(loginResult.OAuthToken)

      await Services.API.Users.GetCookie(loginResult.OAuthToken.TokenId)

      try {
        var me = await Services.API.Users.Me()
        this.$store.commit('identity/setUser', me)
      } catch (error) {
        Logger.Log(LogLevel.Critical, error)
        this.$router.push('/error/500')
      }

      try {
        const branches = await Services.API.Users.GetBusinessBranches()
        this.$store.commit('identity/setBranch', branches[0])
        this.$router.push('/')
        return
      } catch (error) {
        Logger.Log(LogLevel.Critical, error)
        this.$router.push('/error/500')
      }
    }

    this.wrongPassword = !loginResult.Success
    this.isLockedOut = loginResult.IsLockedOut
    this.loading = false
  }

  loggedIn (): void {
    this.$router.push('/')
  }
}
